
















































































































































































































































































































































import CenteredColumnLayout from '@/components/CenteredColumnLayout.vue'
import PageTitle from '@/components/PageTitle.vue'
import { InputSetups } from '../../mixins/input-setups'
import TariffsTagsHelper from '@/mixins/TariffsTagsHelper'
import Placeholders from "@/mixins/placeholders/placeholders";

import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import { StepUnit } from 'piramis-base-components/src/components/PeriodSimplifier/types'
import { MediaEditorMode } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'

import { Component, Mixins } from 'vue-property-decorator'

@Component({
  components: {
    CenteredColumnLayout,
    PageTitle
  },
  data: () => ({
    StepUnit,
    MediaEditorMode
  }),
})
export default class ChatActivitySupport extends Mixins(UseFields, InputSetups, TariffsTagsHelper, Placeholders) {

}
