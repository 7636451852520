var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('centered-column-layout',[_c('page-title',{scopedSlots:_vm._u([{key:"custom-button",fn:function(){return [_c('a-button',{staticClass:"btn-success",on:{"click":function($event){return _vm.$store.dispatch('saveChatConfig')}}},[_vm._v(" "+_vm._s(_vm.$t('chat_config_save_button'))+" ")])]},proxy:true}])}),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_chat_activity_support_title')}},[_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'liven_up_timeout',
          'step': _vm.StepUnit.Minute,
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'send_smile_message',
          'hasAnchor': true,
        }
      }}}),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'send_wakeup_message',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.send_wakeup_message)?_c('nested-content',[_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'wakeup_message',
              'placeholders': _vm.BASE_PLACEHOLDERS,
              'targetToUpload': function () { return _vm.groupUploadTarget; },
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
              'availableButtonsTypes': _vm.defaultMessageEditorButtons,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
            },
          }}})],1):_vm._e()],1),_c('a-divider'),_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'send_admin_message',
          'hasAnchor': true,
        }
      }}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.$store.state.chatState.chat.config.send_admin_message)?_c('nested-content',[_c('time-unit-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'admin_message_interval',
              'step': _vm.StepUnit.Minute,
              'hasAnchor': true,
            }
          }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'admin_message',
              'placeholders': _vm.BASE_PLACEHOLDERS,
              'targetToUpload': function () { return _vm.groupUploadTarget; },
              'editorMode': _vm.MediaEditorMode.TelegramShort,
              'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
              'availableButtonsTypes': _vm.defaultMessageEditorButtons,
              'base-api-url': 'api.chatkeeper.app/cabinet',
              'hasAnchor': true,
            },
          }}}),(_vm.$store.state.chatState.chat.config.remove_previos_admin_message)?_c('switch-input',{attrs:{"setup":{
            'func': _vm.configInputSetup,
            'args': {
              'model': _vm.stateChatConfigPath,
              'key': 'remove_previos_admin_message',
              'hasAnchor': true,
            }
          }}}):_vm._e()],1):_vm._e()],1)],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_entertainment_title')}},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'entertainment_enabled',
          'hasAnchor': true,
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'entertainment_ban_type',
          'options': _vm.banTypes,
          'hasAnchor': true,
        }
      }}}),(_vm.$store.state.chatState.chat.version >= 5000 && _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand)?_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'entertainment_call_enabled',
          'options': _vm.getEntertainmentCallEnabledList,
          'clearable': false,
          'hasAnchor': true,
        }
      }}}):_vm._e()],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_report_title')}},[_c('switch-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'daily_report',
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_crypto_data_title')}},[_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'crypto_charts_permissions',
          'tariffTags': _vm.getTagsByFieldKey('crypto_data'),
          'options': _vm.getCommandPermissionsList,
          'clearable': false,
          'hasAnchor': true,
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'crypto_info_permissions',
          'tariffTags': _vm.getTagsByFieldKey('crypto_data'),
          'options': _vm.getCommandPermissionsList,
          'clearable': false,
          'hasAnchor': true,
        }
      }}}),_c('select-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'exchange_provider',
          'tariffTags': _vm.getTagsByFieldKey('crypto_data'),
          'options': _vm.getExchangeProviderList,
          'clearable': false,
          'hasAnchor': true,
        }
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_first_inactive_config_title')}},[_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'first_inactive_kick_interval',
          'hasAnchor': true,
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'first_inactive_kick_warn1',
          'placeholders': _vm.BASE_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'tariffTags': _vm.getTagsByFieldKey('inactive_kick_warn'),
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'first_inactive_kick_warn2',
          'placeholders': _vm.BASE_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'tariffTags': _vm.getTagsByFieldKey('inactive_kick_warn'),
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}})],1),_c('a-card',{staticClass:"mt-5",attrs:{"title":_vm.$t('field_inactive_config_title')}},[_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'inactive_kick_interval',
          'hasAnchor': true,
        }
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'inactive_kick_warn1',
          'placeholders': _vm.BASE_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'tariffTags': _vm.getTagsByFieldKey('inactive_kick_warn'),
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}}),_c('multi-message-editor-with-media-input',{attrs:{"setup":{
        'func': _vm.configInputSetup,
        'args': {
          'model': _vm.stateChatConfigPath,
          'key': 'inactive_kick_warn2',
          'placeholders': _vm.BASE_PLACEHOLDERS,
          'targetToUpload': function () { return _vm.groupUploadTarget; },
          'tariffTags': _vm.getTagsByFieldKey('inactive_kick_warn'),
          'editorMode': _vm.MediaEditorMode.TelegramShort,
          'hasMedia': _vm.$store.getters.getHaveLicense && _vm.$store.getters.getHaveBrand,
          'availableButtonsTypes': _vm.defaultMessageEditorButtons,
          'base-api-url': 'api.chatkeeper.app/cabinet',
          'hasAnchor': true,
        },
      }}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }